import axios from 'axios';

const apiAuth = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export function sendSms(phoneNumber, phoneCountryCode, phoneDialCode) {
  return apiAuth.post(`/send-sms-code`, { phone: phoneNumber, phoneCountryCode, phoneDialCode });
}

export function verifyCode(phone, code) {
  return apiAuth.post(`/verify-sms-code`, { phone, code });
}
