
const translations = {
    ru: {
        subtitle: "Ваш помощник в написании текстов для сайтов на базе искусственного интеллекта",
        ctaButtonText: "В список ожидания",

        // Features
        featuresTitle: "Возможности Text Copilot",

        features1Title: "Комментарии на LinkedIn",
        features1Text: "Пример как Text Copilot продолжит текст вашего комментария на LinkedIn, основываясь на тексте поста.",

        features2Title: "Twitter",
        features2Text: "Наше расширение специально адаптированно для таких сайтов как Twitter, LinkedIn, Facebook, Reddit, ProductHunt, Gmail, Telegram. И мы продолжаем добавлять новые платформы.",

        features3Title: "Сопроводительное письмо",
        features3Text: "На всех остальных сайтах Text Copilot поможет вам написать тексты основываясь на всём тексе сайта, либо на контекте, который вы ему укажете.",

        features4Title: "Любой сайт",
        features4Text: "Если Вы не предоставите контекст, то Text Copilot будет использовать текст на сайте для продолжения Вашего текста.",

        features5Title: "Отвечайте на письма",
        features5Text: "Text Copilot поможет вам ответить на письма, основываясь на тексте письма.",

        features6Title: "Поиск информации в интернете",
        features6Text: "В расширении можно быстро искать информацию в интернете как на основе контекста, так и без него. А результат ответа можно добавить в контекст.",

        // Form
        formTitle: "Присоединяйтесь к списку ожидания",
        formSubtitle: "Получите ранний доступ и скидку на Text Copilot, когда он будет доступен.",
        formButton: "Присоединиться",
        formResponseSuccess: "Спасибо за присоединение к списку ожидания!",
    },
    en: {
        subtitle: "Enhance your writing on any website with AI-powered text completion",
        ctaButtonText: "Join waitlist",

        // Features
        featuresTitle: "Text Copilot Features",

        features1Title: "LinkedIn Comments",
        features1Text: "Example of how Text Copilot can continue your LinkedIn comment based on the post text.",

        features2Title: "Twitter",
        features2Text: "Our extension is specifically adapted for platforms like Twitter, LinkedIn, Facebook, Reddit, ProductHunt, Gmail, Telegram. And we keep adding new platforms.",

        features3Title: "Cover Letter",
        features3Text: "On all other websites Text Copilot will help you write texts based on all the text on the site, or on the content you specify to it.",

        features4Title: "Any Website",
        features4Text: "If you don't provide context, Text Copilot will use the text on the site to continue your text.",

        features5Title: "Reply to Emails",
        features5Text: "Text Copilot will help you reply to emails based on the text of the email.",

        features6Title: "Search the Internet",
        features6Text: "In the extension, you can quickly search the Internet based on the context or without it. And the answer result can be added to the context.",

        // Form
        formTitle: "Join the waitlist",
        formSubtitle: "Get early access and a discount on Text Copilot when it becomes available.",
        formButton: "Join",
        formResponseSuccess: "Thank you for joining the waitlist!",
    },
};

export default translations;
