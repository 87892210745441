import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from './VideoPlayer.js';

import styles from './styles/СapabilityBox.module.css';

const СapabilityBox = ({ title, text, image, video, position }) => {
    const renderImage = useCallback(() => {
        return (
            <img src={image} alt={title} className={styles.capabilityImage} />
        );
    }, [image, title]);

    const renderVideo = useCallback(() => {
        return (
            <VideoPlayer src={video} type="video/mp4" />
        );
    }, [video]);

    const renderMedia = useCallback(() => {
        console.log('video', video, 'image', image);
        return video ? renderVideo() : renderImage();
    }, [video, image, renderVideo, renderImage]);

    return (
        <div className={styles.capabilityBox}>
            {position === 'left' && renderMedia()}
            <div className={styles.capabilityText}>
                <h3 className={styles.capabilityTitle}>{title}</h3>
                <p className={styles.capabilityDescription}>{text}</p>
            </div>
            {position === 'right' && renderMedia()}
        </div>
    );
};

СapabilityBox.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string,
    video: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']),
};

СapabilityBox.defaultProps = {
    image: null,
    video: null,
    position: 'right',
};

export default memo(СapabilityBox);
