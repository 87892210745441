import React, { useContext, useCallback, useEffect, useState, useMemo, memo } from "react";
import { submitApplication } from './utils/api.js';

import СapabilityBox from './components/СapabilityBox.js';

import { LanguageContext } from '../routes/LanguageContext.js';
import translations from './language/LandingContainer.translations.js';

import styles from './styles/LandingContainer.module.css';

const LandingContainer = () => {
  const { language, toggleLanguage, startLanguage } = useContext(LanguageContext);
  const t = translations[language];

  const [isVisible, setIsVisible] = useState(true);
  const [formResponse, setFormResponse] = useState(null);

  const capabilitiesBlocks = useMemo(() => [
    {
      title: t.features1Title,
      text: t.features1Text,
      video: "../../videos/linkedin.mp4",
      position: "right",
    },
    {
      title: t.features2Title,
      text: t.features2Text,
      video: "../../videos/twitter.mp4",
      position: "left",
    },
    {
      title: t.features3Title,
      text: t.features3Text,
      video: "../../videos/openai.mp4",
      position: "right",
    },
    {
      title: t.features4Title,
      text: t.features4Text,
      video: "../../videos/medium.mp4",
      position: "left",
    },
    {
      title: t.features5Title,
      text: t.features5Text,
      video: "../../videos/gmail.mp4",
      position: "right",
    },
    {
      title: t.features6Title,
      text: t.features6Text,
      image: "../../images/site/shots4.png",
      position: "left",
    },
  ], [t]);

  const scrollToForm = useCallback(() => {
    const formElement = document.getElementById("request");
    if(formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100;
      setIsVisible(window.scrollY < threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    const formData = {
      email: event.target.email.value,
    };

    try {
      const response = await submitApplication(formData);
      if (response.status === 200) {
        setFormResponse(t.formResponseSuccess);
      } else {
        setFormResponse(null);
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      setFormResponse(null);
    }
  }, [t]);

  return (
    <div className={styles.landingPage}>
      <div className={styles.buttonsContainer}>
        {startLanguage === 'ru' &&
          <button onClick={toggleLanguage} className={styles.languageButton} style={{ opacity: isVisible ? 1 : 0 }}>{language}</button>
        }
        <a onClick={scrollToForm} href="#requestForm" className={styles.ctaButton}>{t.ctaButtonText}</a>
      </div>

      <div className={styles.firstBlock}>
        <img src="../../images/site/logo_black.png" alt="ggsite logo" className={styles.logo} />
        <div className={styles.titleBox}>
          <h1 className={styles.title}>Text</h1>
          <img
            src="../../images/site/text-copilot-logo.png"
            alt="text copilot logo"
            className={styles.titleBot}
            onClick={scrollToForm}
          />
          <h1 className={styles.title}>Copilot</h1>
        </div>
        <p className={styles.subtitle}>{t.subtitle}</p>
        <a onClick={scrollToForm} href="#requestForm" className={styles.requestButton}>{t.ctaButtonText}</a>
        <a
          href="https://www.producthunt.com/posts/text-copilot?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-text&#0045;copilot"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=481256&theme=light" 
            alt="Text&#0032;Copilot - AI&#0032;assistant&#0032;that&#0032;continues&#0032;your&#0032;text&#0032;on&#0032;any&#0032;website | Product Hunt" 
            style={{ width: "250px", height: "54px" }}
            width="250"
            height="54"
          />
        </a>
      </div>

      <div className={styles.secondBlock}>
        <h2 className={styles.blockTitle}>{t.featuresTitle}</h2>

        {capabilitiesBlocks.map((block, index) => (
          <СapabilityBox key={index} {...block} />
        ))}
      </div>

      <div id="requestForm" className={styles.formContainer}>
        <h2 className={styles.blockTitle}>{t.formTitle}</h2>
        <p className={styles.text} style={{ textAlign: "center" }}>{t.formSubtitle}</p>

        {formResponse && <p className={styles.formResponse}>{formResponse}</p>}
        {!formResponse && (<form className={styles.formBox} onSubmit={handleSubmit}>
          <input type="text" placeholder="Email" className={styles.formInput} name="email" required />
          <button type="submit" className={styles.requestButton} style={{ margin: "auto" }}>{t.formButton}</button>
        </form>)}
      </div>
    </div>
  );
};

export default memo(LandingContainer);
