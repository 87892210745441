import React, { useState, useCallback, useEffect, memo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import { sendSms, verifyCode } from '../api';
import styles from './styles/Login.module.css';
import { useForm } from 'react-hook-form';

// TODO: Сделать на двух языках

const Login = () => {
    const { formState: { errors } } = useForm();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('us');
    const [phoneDialCode, setPhoneDialCode] = useState('1');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(1);
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();

    const location = useLocation();
    const currentQuery = location.search;

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    const handlePhoneSubmit = useCallback(async (e) => {
        e?.preventDefault();
        if (timer > 0) return;

        try {
            await sendSms(phoneNumber, phoneCountryCode, phoneDialCode);
            setMessage('');
            setStep(2);
            setTimer(60);
        } catch (error) {
            setMessage(error?.response?.data?.message);
        }
    }, [phoneCountryCode, phoneDialCode, phoneNumber, timer]);

    const handleCodeSubmit = useCallback(async (e, otp) => {
        e?.preventDefault();
        const codeValue = otp || code;
        if (codeValue.length < 4) return;

        try {
            await verifyCode(`+${phoneNumber}`, codeValue);

            const redirectTo = location.state && location.state.from 
                ? location.state.from.pathname + location.state.from.search
                : `/app${currentQuery}`;

            navigate(redirectTo);
        } catch (error) {
            setMessage(error?.response?.data?.message);
        }
    }, [code, currentQuery, location, navigate, phoneNumber]);

    const handleOtpChange = useCallback(otp => {
        setCode(otp);
        if (otp.length === 4) {
            handleCodeSubmit(null, otp);
        }
    }, [handleCodeSubmit]);

    return (
        <div className={styles.loginPage}>
            <div className={styles.logoContainer}>
                <Link to="/">
                    <img src="../../images/site/text-copilot-logo.png" alt="Text Copilot Logo" className="icon" />
                </Link>
            </div>
            <div className={styles.loginContainer}>
                {step === 1 ? (
                    <>
                        <div className={styles.loginBox}>
                            <h1 className={styles.loginTitle}>Введите номер телефона</h1>
                            <h2 className={styles.loginTitle}>чтобы войти или зарегистрироваться на GETHOME</h2>
                        </div>
                        <form onSubmit={handlePhoneSubmit} className={styles.loginForm}>
                            <PhoneInput
                                country={'us'} // Set default country to USA
                                value={phoneNumber}
                                onChange={(value, country) => {
                                    setPhoneNumber(value.replace(`+${country.dialCode}`, '').trim());
                                    setPhoneDialCode(country.dialCode);
                                    setPhoneCountryCode(country.countryCode);
                                }}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                    className: styles.loginInput,
                                }}
                                inputStyle={{ width: '100%' }}
                                containerStyle={{ marginBottom: '15px' }}
                                enableAreaCodes={true}
                                enableAreaCodeStretch={true}
                                enableLongNumbers={false}
                                masks={{ us: '(...) ...-....' }} // Example mask for USA
                                buttonStyle={{
                                    background: 'none',
                                    border: 'none',
                                    height: '40px',
                                    marginTop: '5px',
                                    marginLeft: '10px',
                                }}
                                dropdownStyle={{
                                    backgroundColor: 'white',
                                    borderRadius: '20px',
                                    border: '2px solid black',
                                    width: '280px',
                                    boxShadow: 'none',
                                }}
                            />
                            {errors.phoneNumber && <span className={styles.errorText}>Введите корректный номер телефона</span>}
                            <button type="submit" className={styles.loginButton}>Продолжить</button>
                            <p className={styles.privacyText}>
                                Нажимая «Продолжить», я принимаю условия <Link to="/privacy" className={styles.privacyLink}>Пользовательского соглашения</Link>
                            </p>
                        </form>
                    </>
                ) : (
                    <>
                        <div className={styles.loginBox}>
                            <h1 className={styles.loginTitle}>Введите код из СМС</h1>
                            <h2 className={styles.loginTitle}>Код отправлен на номер телефона<br />+{phoneNumber}</h2>
                        </div>
                        <form onSubmit={handleCodeSubmit} className={styles.loginForm}>
                            <div className={styles.otpContainer}>
                                <OtpInput
                                    value={code}
                                    onChange={handleOtpChange}
                                    numInputs={4}
                                    shouldAutoFocus
                                    renderInput={props => <input {...props} />}
                                    inputStyle={styles.otpInput}
                                />
                            </div>
                            <button type="submit" className={styles.loginButton}>Подтвердить</button>
                            <button type="button" onClick={handlePhoneSubmit} className={styles.resendButton} disabled={timer > 0}>
                                {timer > 0 ? `Отправить код повторно через ${timer} секунд` : 'Отправить код повторно'}
                            </button>
                        </form>
                    </>
                )}
                {message && <p className={styles.messageText}>{message}</p>}
            </div>
        </div>
    );
};

export default memo(Login);
