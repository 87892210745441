import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { PrivateWrapper, CheckAuthWrapper } from './Wrappers';
import { LanguageProvider } from './LanguageContext';

// langind
import LandingContainer from '../landing/LandingContainer';
import PrivacyPage from '../landing/PrivacyPage';

// login
import Login from '../auth/components/Login';

// app
// import App from '../app/components/App';
const App = () => { // for testing
  return (
    <div>
      <h1>App</h1>
    </div>
  );
};

const AppRoutes = () => {
  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<LandingContainer />} />
        <Route path="/privacy" element={<PrivacyPage />} />

        <Route element={<CheckAuthWrapper />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PrivateWrapper />}>
          <Route path="/app/*" element={<App />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LanguageProvider>
  );
};

export default AppRoutes;
